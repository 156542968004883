.animated-counter {
    margin: 5rem 1rem 3rem;
    width: 15rem;
    color: #4f4d4d;

    * {
        display: block;
        text-decoration: none;

    }

    b {
        font-size: 1.25rem;
        font-weight: normal;
    }
    u {
        font-family: 'CoreSansAR', sans-serif;
        font-size: 4.2rem;
        font-weight: 200;
        font-style: normal;
        color: #000;
    }
    s {
        font-size: 3rem;
        //font-weight: 700;
        font-style: normal;
    }
    i {
        font-size: 1.25rem;
    }
}

@media only screen and (max-width: 1500px) {
    .animated-counter {
        margin: 3rem 1rem 2rem;
    }
}

@media only screen and (max-width: 1200px) {
    .animated-counter {
        b {
            font-size: .8rem;
        }
        u {
            font-size: 3.2rem;
        }
        s {
            font-size: 1.8rem;
        }
        i {
            font-size: .8rem;
        }
    }
}

@media only screen and (max-width: 780px) {
    .animated-counter {
        width: 100px;
        margin: 5rem .2rem 3rem;

        b {
            font-size: .7rem;
            height: 1.6rem;
        }
        u {
            font-size: 2rem;
        }
        s {
            font-size: 1rem;
        }
        i {
            font-size: .7rem;
        }
    }
}

@media only screen and (max-width: 350px) {
    .animated-counter {
        u {
            font-size: 1.8rem;
        }
        s {
            font-size: .9rem;
            font-size: .7rem;
        }
    }
}
