
.carousel {
    position: relative;
    padding-top: 5rem !important;
    overflow: hidden;

    .canvas {
        height: 20rem;
        width: 1200px;
        display: block;
        margin: 0 auto;
        position: relative;

        .actions {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 5rem;

            display: flex;
            justify-content: center;
            align-items: center;

            // cursor: pointer;
        }
        .prev {
            left: -5rem;
            transform: scale(-1, 1);
        }
        .next {
            right: -5rem;
        }
        svg {
            max-width: 40px;

            path {
                fill: #ECEFF1;
                cursor: pointer;

                &:hover {
                    fill: #CFD8DC;
                }
            }
        }
    }

    .bullets {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .bullet {
            width: 16px;
            height: 16px;
            background: #9e9e9e;
            margin: 3px 7px;
            border-radius: 50%;
            cursor: pointer;

            transition: background ease-in-out .1s;
        }
        .bullet.active {
            background: #282beb;
        }
    }

    .description {
        color: #7f7f85;
        height: 12rem;
        overflow: hidden;

        font-size: 1.3rem;
        font-style: italic;
        text-align: center;

        width: 1200px;
        margin: 0 auto;

        padding-top: 1.5rem;
    }
}

@media only screen and (max-width: 1400px) {
    .carousel {
        padding-top: 1rem !important;

        .canvas {
            width: 90%;
            height: 16.5rem;

            .actions {
                justify-content: start;
                padding-left: 5px;
            }
        }

        .description {
            font-size: 1rem;
            width: 90%;
            height: 6.5rem;
            padding-top: 1rem;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .carousel {
        .canvas {
            height: 15rem;
        }
    }
}

@media only screen and (max-width: 800px) {
    .carousel {
        .canvas {
            display: block;
            height: 12rem;
            width: 100%;
            padding: 0 !important;

            .prev {
                left: 0;
            }
            .next {
                right: 0;
            }
        }

        .description {
            font-size: .8rem;
            width: 90%;
            height: 6.5rem;
            padding-top: 1rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .carousel {
        .canvas {
            display: block;
            height: 12rem;
            width: 100%;
            padding: 0 !important;
        }

        .description {
            font-size: .55rem;
            width: 90%;
            height: 6.5rem;
            padding-top: 1rem;
        }
    }
}