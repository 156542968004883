.section-container {
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    z-index: 500;

    //overflow: hidden scroll;

    //scroll-snap-type: y mandatory;

    .page-section {
        position: relative;
        height: 100vh;
        width: 100%;
        //scroll-snap-align: start;

        .background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }

        [data-content] {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }
    }
}
