// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

@font-face {
    font-family: 'MyriadPro';
    font-style: italic;
    font-weight: 400;
    src: local('Myriad Pro Italic'), local('Myriad Pro-Italic'), local('MyriadPro-It'), local('sans-serif'), url(/fonts/MyriadPro-It.woff) format('woff');
}
@font-face {
    font-family: 'MyriadPro';
    font-weight: bold;
    src: local('Myriad Pro Bold'), local('Myriad Pro-Bold'), local('MyriadPro-Bold'), local('sans-serif'), url(/fonts/MyriadPro-Bold.woff) format('woff');
}
@font-face {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    src: local('Myriad Pro'), local('Myriad Pro'), local('MyriadPro'), local('sans-serif'), url(/fonts/MyriadPro-Regular.woff) format('woff');
}
@font-face {
    font-family: 'MyriadProCond';
    font-weight: bold;
    src: local('Myriad Pro-Cond Bold'), local('sans-serif'), url(/fonts/MyriadPro-BoldCond.woff) format('woff');
}


@font-face {
    font-family: 'AvenirNextLTPro';
    font-weight: bold;
    src: local('AvenirNextLTPro Bold'), local('AvenirNextLTPro-Bold'), local('sans-serif'), url(/fonts/AvenirNextLTPro-Bold.woff) format('woff');
}
@font-face {
    font-family: 'AvenirNextLTPro';
    font-style: normal;
    font-weight: 400;
    src: local('AvenirNextLTPro'), local('AvenirNextLTPro-Regular'), local('sans-serif'), url(/fonts/AvenirNextLTPro-Regular.woff) format('woff');
}

@font-face {
    font-family: 'CoreSansAR';
    font-style: normal;
    font-weight: 200;
    src: local('CoreSansAR-15Thin'), local('Core Sans AR 15 Thin'), local('sans-serif'), url(/fonts/CoreSansAR-15Thin.woff) format('woff');
}


* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    color: #808285;

    font-family: 'MyriadPro', 'Roboto', sans-serif;
    user-select: none;

    font-size: 16px;

    //height: 100%;
    //overflow: hidden;
}

html.no-safari {
    height: 100%;
    overflow: hidden;

    body {
        height: 100%;
        overflow: hidden;

        #root {
            height: 100%;
            overflow-x: hidden;
            overflow-y: scroll;
            -webkit-overflow-scrolling: auto;
        }
    }
}

.pull-right { float: right }
.container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 15px;
}
.relative { position: relative; }
.grow {
    flex-grow: 1;
    flex-basis: 0;
}

.header-ref {
    padding-top: 5rem;
}
.home-page .header-ref {
    padding-top: 0;
}

.home-page .AppHeader {
    transform: translate3d(0, -100%, 0);
}
.home-page.top-of-page .AppHeader {
    .login-form {
        background: #ffffff61;
    }
}
.home-page.header-fixed .AppHeader {
    transform: translate3d(0, 0, 0);
    position: fixed;
}

.top-of-page .AppHeader {
    transform: translate3d(0, 0, 0);
}
.AppHeader {
    display: flex;
    transform: translate3d(0, 0, 0);
    transition: transform ease-in-out .25s;

    color: #fff;
    background: linear-gradient(to right, #7f30cb, #d03882, #fd9a10);
    // height: 2rem;

    //position: sticky;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 1000;

    .logo {
        // background: url("/logo.svg");
        // background-size: contain;
        // background-repeat: no-repeat;
        // background-position: center center;
        width: 150px;
        display: flex;
        align-items: center;

        svg {
            max-height: 1.8rem;
            width: 100%;
        }

        a {
            padding: 0;
            background: none !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .menu {
        flex-grow: 1;
        flex-basis: 0;
    }

    a {
        display: inline-block;
        color: #fff;
        text-decoration: none;
        font-size: .8rem;
        font-weight: 300;
        line-height: 2rem;
        text-transform: uppercase;
        padding: 1rem;

        transition: background .2s ease-in-out;

        &:hover {
            background: #00000026;
        }
    }

    .login-form {
        position: absolute;
        left: -1.2rem;
        right: -1.2rem;
        top: 100%;

        background: #607d8b;
        padding: .8rem;
        border-radius: 0 0 10px 10px;

        text-align: center;

        input {
            display: block;
            width: 100%;
            border-radius: 7px;
            border: 0;
            padding: .4rem 1rem;
            margin-bottom: .5rem;
            color: #919191;

            &::placeholder {
                font-style: italic;
            }
        }
        button {
            background: #fff;
            border: 0;
            border-radius: 7px;
            padding: .25rem 1rem;
            font-size: .7rem;
            font-style: italic;
            font-weight: bold;
            color: #919191;
        }

        // efeitos
        transform: scale3d(1, 0, 1);
        transform-origin: top;
        transition: transform ease-in-out .25s;
        &.visible {
            transform: scale3d(1, 1, 1);
        }
    }

    .right {
        margin-right: 1.8rem;
    }
}

footer {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    z-index: 1000;

    background: #fff;

    section.address {
        display: flex;
        margin-bottom: 4rem;
    }

    .address-entry {
        flex-grow: 1;
        flex-basis: 0;

        margin: 2rem .5rem;

        h2 {
            margin: 0 0 1rem;
            color: #595959;
        }

        div {
            font-size: 1.1rem;
            color: #b7b7b7;
            white-space: pre-wrap;
            font-style: italic;
        }
    }

    .footer-info {
        background: #f1f2f2;
        padding: 2rem;

        .grid {
            display: grid;
            grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 2.5fr;

            * {
                padding: .5rem;
            }

            .filler {
                grid-row-end: span 3;
            }
            .logo {
                grid-column-end: span 3;

                img {
                    height: 60px;
                }

                svg {
                    height: 60px;

                    path {
                        fill: #808285 !important;
                    }
                }
            }
            .copyright {
                grid-column-end: span 4;
            }
            // .default {
            //     text-align: center;
            // }

            .social {
                svg {
                    height: 50px;
                }
            }
        }

        a {
            color: #808285;
        }
    }
}

@media only screen and (max-width: 1920px) {
    html, body {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1600px) {
    footer {
        .footer-info {
            .grid {
                grid-template-columns: .5fr 1fr 1fr 1fr 1fr .5fr;
            }
        }
    }
}

@media only screen and (max-width: 1350px) {
    .AppHeader a {
        line-height: 1.2rem;
    }

    footer {
        section.address {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 925px) {
    .AppHeader .menu > * {
        display: none;
    }
}

@media only screen and (max-width: 770px) {
    footer {
        section.address {
            display: grid;
            grid-template-columns: 1fr;
        }

        .address-entry {
            margin: 1rem .5rem;

            h2 {
                font-size: 1rem;
                margin: 0 0 .4rem;
            }

            div {
                font-size: .9rem;
            }
        }

        .footer-info {
            .grid {
                grid-template-columns: .1fr 1fr 1fr 1fr 1fr .1fr;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    footer {
        .address-entry {
            h2 {
                font-size: .95rem;
            }

            div {
                font-size: .8rem;
            }
        }

        .footer-info {
            .grid {
                font-size: .8rem;

                .default {
                    grid-column-end: span 4;
                }

                .filler {
                    grid-row-end: span 6;
                }
                .copyright {
                    font-size: .6rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .AppHeader {
        font-size: .8rem;
        .logo {
            width: 80px;
        }
    }
}
