.header-fixed .hero-simple {
    padding-top: 2rem;
}
.hero-simple.avenir {
    h1, div.text {
        font-family: 'AvenirNextLTPro', 'MyriadPro', sans-serif;
    }
}
.hero-simple {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: #fff;
    text-align: center;

    background: #0007;

    transform: translate3d(0, 0, 0);

    h1 {
        font-size: 4rem;
        font-weight: 700;
        margin: 0;
    }
    div.text {
        font-size: 2rem;
        white-space: pre-wrap;
    }

    div.link-container {
        padding-top: .5rem !important;
        padding-bottom: 1rem !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        a {
            width: max-content;
        }
    }
    .link {
        display: inline-block;
        background: #b72c93;
        color: #fff;
        text-decoration: none;
        font-style: normal;
        font-size: .9rem;
        padding: 0 2.8rem;
        line-height: 1.7rem;
        border-radius: 20px;
        transform: translate3d(0, 0, 0) !important;
    }
    .link.orange {
        background: #f5721a;
    }
    .link.blue {
        background: #282beb;
    }

    .icon {
        min-width: 70px;
        height: 70px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-bottom: 1rem;
    }

    // Animations
    [data-animated] {
        opacity: 0;
        transform: translate3d(0, 30px, 0);

        transition: opacity 800ms var(--delay),
        transform 800ms cubic-bezier(0.13, 0.07, 0.26, 0.99) var(--delay);

        will-change: transform;
        will-change: opacity, transform;
    }

}

// Animations
.is-visible .hero-simple {
    [data-animated] {
        opacity: 1;
        transform: translate3d(0, 1px, 0);
    }
}

.hero-simple.hero2 {
    padding-left: 50%;
    padding-right: 10%;

    text-align: left;
    color: #808285;
    background: none;

    h1 {
        font-size: 3rem;
        white-space: pre-wrap;
        margin-bottom: 2rem;
    }

    div.text {
        font-style: italic;
        font-size: 1.8rem;
    }

    .bg-hero {
        position: absolute;
        left: 0;
        top: 5%;

        height: 140vh;
        max-width: 48vw;
        z-index: -1;
    }
}

.hero-simple.hero3 {
    background: none;
    color: #808285;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 1.7rem;
    }
    div.text {
        font-size: 1.3rem;
        font-style: italic;
        text-align: center;
    }

    .counters {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 3rem;
    }

    .grid-comm {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5rem;

        padding: 5rem 10rem;

        max-width: 1450px;
        margin: 0 auto;
        width: 100%;

        span {
            font-weight: 700;
            font-style: normal;
        }
    }
}

@media only screen and (max-width: 925px) {
    .hero-simple {
        h1 {
            font-size: 3rem;
        }
        div.text {
            font-size: 1.4rem;
        }
    }
    .hero-simple.hero2,
    .hero-simple.hero3 {
        h1 {
            font-size: 1.75rem;
            white-space: normal;
            padding: 0 15px;
        }
        div.text {
            font-size: 1.1rem;
            white-space: normal;
            padding: 0 15px;
        }

        .bg-hero {
            height: 80vh;
            max-width: 40vw;
        }
    }
}

@media only screen and (max-width: 768px),
    only screen and (max-height: 860px) {
    .hero-simple {
        h1 {
            font-size: 2.4rem;
        }
        div.text {
            font-size: 1.2rem;
        }

        .icon {
            min-width: 50px;
            height: 50px;
        }
    }

    .hero-simple.hero2 {
        h1 {
            font-size: 2rem;
        }

        div.text {
            font-size: 1.2rem;
        }
    }

    .hero-simple.hero3 {
        h1 {
            font-size: 2rem;
        }
        div.text {
            font-size: 1rem;
        }

        .grid-comm {
            gap: 3.5rem;
            padding: 3.5rem 5rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .hero-simple {
        h1 {
            font-size: 1.2rem;
        }
        div.text {
            font-size: .7rem;
            padding: 0 1rem;
            white-space: normal;
        }

        .icon {
            min-width: 50px;
            height: 50px;
        }
        .link {
            font-size: .8rem;
        }

        div.link-container {
            padding-bottom: .5rem !important;
        }
    }

    .hero-simple.hero2 {
        padding-left: 15%;

        h1 {
            font-size: 1.3rem;
        }

        div.text {
            font-size: .8rem;
        }

        .bg-hero {
            height: 30vh;
        }
    }

    .hero-simple.hero3 {
        h1 {
            font-size: 1.3rem;
        }
        div.text {
            font-size: .8rem;
        }

        .grid-comm {
            gap: 3.5rem;
            padding: 3.5rem 5rem;
        }
    }
}

@media only screen and (max-width: 430px) {
    .hero-simple {
        .icon {
            min-width: 32px;
            height: 32px;
            margin-bottom: 0;
        }
    }

    .hero-simple.hero3 {
        .counters {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem 0;
            margin: 1.5rem 0;

            .animated-counter {
                margin: .2rem 0rem .3rem;
                justify-self: center;

                b {
                    padding-top: .2rem;
                    height: 1.2rem;
                }
            }
        }

        .grid-comm {
            //display: none;
            gap: 2rem 1rem;
            padding: 1rem .2rem;
            margin: 2rem 0;
        }
    }
}

@media only screen and (max-width: 350px) {
    .hero-simple {
        .icon {
            min-width: 25px;
            height: 25px;
            margin-bottom: 0;
        }

        .link {
            //padding: .2rem 1.5rem .3rem;
            line-height: 1.3rem;
            font-size: .7rem;
        }
    }

    .hero-simple.hero3 {

        h1 {
            font-size: 1rem;
            margin-bottom: .5rem;
        }
        div.text {
            font-size: .7rem;
        }

        .counters {
            display: grid;
            gap: .8rem 0;
            margin: 1.2rem 0;

            .animated-counter {
                margin: .2rem 0rem .3rem;
            }
        }
    }
}
